import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import subcategories from '../../assets/subCategoriesData.json';
import subcategoriesBrands from '../../assets/subCategoryBrands.json';
import subData from '../../assets/subData.json';
import Product from './../../components/Product/index';
import './styles.css';

const Categories = () => {

    const location = useLocation();
    let { category } = useParams();
    category = category.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    const [subcategory, setSubcategory] = useState([]);
    const [brands, setBrands] = useState([]);
    const [products, setProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [categoryProducts, setCategoryProducts] = useState([]);
    const [allBrands, setAllBrands] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [previousCategory, setPreviousCategory] = useState(null);
    const [previousBrand, setPreviousBrand] = useState(null);

    // Set category subcategories and brands
    useEffect(() => {
        const data = subcategories[category]
        const brand = subcategoriesBrands[category]
        brand.sort((a, b) => a.name.localeCompare(b.name))
        setSubcategory(data);
        setBrands(brand);
        setAllBrands(brand);
    }, [category]);

    // Set products using location state
    useEffect(() => {
        let allProducts = location.state?.data;
        var matchedProducts = allProducts.filter(
            product => product.Category.includes(category));
        setProducts(matchedProducts);
        setAllProducts(matchedProducts);

        // Resets states when changing category pages
        setSelectedBrand(null);
        setPreviousBrand(null);
        setSelectedCategory(null);
        setPreviousCategory(null);
    }, [location.state]);

    const filterProducts = (name, type) => {
        // Category filtering
        if (type === "category") {
            // If already selected, deselect
            if (previousCategory === name) {
                setSelectedCategory(null);
                setPreviousCategory(null);
                setBrands(allBrands);
                setProducts(allProducts);
            }
            // Else display category products and brands
            else {
                // Update products by subcategory
                var matchedProducts = allProducts.filter(product => product.Subcategory == name);
                setProducts(matchedProducts);
                setCategoryProducts(matchedProducts);
                setSelectedCategory(name);
                setPreviousCategory(name);

                // Update brand
                setSelectedBrand(null);
                setPreviousBrand(null);
                setBrands(subData[name].sort((a, b) => a.name.localeCompare(b.name)))
            }
        }

        // Brand filtering
        else {
            // If already selected, deselect
            if (previousBrand === name) {
                setSelectedBrand(null);
                setPreviousBrand(null);

                // If a category selected, update products
                if (selectedCategory) {
                    setProducts(categoryProducts)
                }
                // Else reset products
                else {
                    setProducts(allProducts);
                }
            }

            // Else display brand products
            else {
                var matchedProducts = allProducts.filter(
                    product => product.Brand.includes(name));
                setProducts(matchedProducts);
                setSelectedBrand(name);
                setPreviousBrand(name);
            }
        }
    }

    return (
        <div className='categories-page'>
            <h1>{category}</h1>
            <h2>Categories</h2>
            <div className='subCategories'>
                {subcategory.map((subcategory) => (
                    <div onClick={() => filterProducts(subcategory.name, "category")}>
                        <div className='subCategory'>
                            <img className={`subCategory-image ${selectedCategory && selectedCategory !== subcategory.name ? 'grayed-out' : ''}`} src={subcategory.imageURL} alt={`Image: ${subcategory.imageUrl}`} />
                        </div>
                        <h2>{subcategory.name}</h2>
                    </div>
                ))}
            </div>
            <h2>Brands</h2>
            <div className='subCategories'>
                {brands.map((brand) => (
                    <img className={`brand-image ${selectedBrand && selectedBrand !== brand.name ? 'grayed-out' : ''}`} src={brand.imageURL} onClick={() => filterProducts(brand.name, "brand")} />
                ))}
            </div>
            <div>
                <h2>Products</h2>
                <div className='products'>
                    {products.map((product) => {
                        return (
                            <Product
                                id={product.id}
                                Brand={product.Brand}
                                Name={product.Name}
                                Image={product.Image}
                                Size={product.Size}
                                Cost={product.Cost}
                                Quantity={product.Quantity}
                            ></Product>
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

export default Categories;