// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
const { REACT_APP_API_KEY } = process.env;

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: REACT_APP_API_KEY,
  authDomain: "banana-couponing.firebaseapp.com",
  projectId: "banana-couponing",
  storageBucket: "banana-couponing.appspot.com",
  messagingSenderId: "353852676342",
  appId: "1:353852676342:web:d99a9a8d57955a8f2e34d7"

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);