import React from 'react';
import { Link } from "react-router-dom";
import './styles.css';

export default function Cart() {

    return (
        <div>
            Cart Page
            <Link to="/checkout" className='header-logo'>
                <button>Checkout</button>
            </Link>
        </div>
    );
};