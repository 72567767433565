import { Routes, Route } from 'react-router-dom';

import Header from './parts/Header/index';
import Home from './pages/Home/index';
import Admin from './pages/Admin/index';
import Login from './pages/Login/index';
import Search from './pages/Search/index';
import Cart from './pages/Cart/index';
import Checkout from './pages/Checkout/index';
import Categories from './pages/Categories/index';
import Category from './pages/Category/index';
import { useState } from 'react';

export default function App() {
  const [products, setProducts] = useState([]);
  return (
    <div>
      <Header products={products}/>
      <Routes>
          <Route path="/" element={<Home setProducts={setProducts}/>} />
          <Route path="/search" element={<Search />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/categories/:category" element={<Categories />} />
          <Route path="/category" element={<Category />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<Admin />} />
      </Routes>
    </div>
  );
}