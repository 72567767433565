import React from 'react';
import { collection, addDoc, updateDoc, doc } from "firebase/firestore";
import { db } from '../../adapters/firebase';
import { useState, useEffect } from 'react';
import Product from '../Product';
import categoryData from '../../assets/subCategoriesData.json';
import brandData from '../../assets/subData.json';
import './styles.css';

export default function ProductForm(props) {

    // Product states
    const [productName, setProductName] = useState('');
    const [productBrand, setProductBrand] = useState('');
    const [customBrand, setCustomBrand] = useState('');
    const [productCategory, setProductCategory] = useState('');
    const [productSubcategory, setProductSubcategory] = useState('');
    const [productImage, setProductImage] = useState('');
    const [productSize, setProductSize] = useState('');
    const [productCost, setProductCost] = useState(0);
    const [productQuantity, setProductQuantity] = useState(0);

    // Dropdown states
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [brands, setBrands] = useState([]);

    // Set inital categories and brands
    useEffect(() => {
        if (props.allProducts) {
            const uniqueCategories = Object.keys(categoryData);
            setCategories(uniqueCategories);
            handleCategoryChange('Personal Care')
        }
    }, [props.allProducts]);


    const handleCategoryChange = (category) => {
        // Update selected category and fetch corresponding subcategories
        setProductCategory(category);
        const categorySubcategories = categoryData[category];
        setSubcategories(categorySubcategories);
        handleSubcategoryChange(categorySubcategories[0].name)
    };

    const handleSubcategoryChange = (subcategory) => {
        // Update selected subcategory
        setProductSubcategory(subcategory);

        // Update brand dropdown
        const subcatBrands = Array.from(new Set(brandData[subcategory].map((brand) => brand.name)));
        setBrands(subcatBrands);
    };

    const handleBrandChange = (brand) => {
        // Update selected brand
        setProductBrand(brand);
        setCustomBrand('')
    };

    const handleCustomBrandChange = (e) => {
        // Update custom brand input value
        setCustomBrand(e.target.value);
    };

    useEffect(() => {
        // If a product is provided, populate the modal fields with its information
        if (props.product) {
            // console.log(props.product);
            setProductName(props.product.Name);
            setProductCategory(props.product.Category);
            handleCategoryChange(props.product.Category)
            setProductSubcategory(props.product.Subcategory);
            handleBrandChange(props.product.Brand)
            setProductImage(props.product.Image);
            setProductSize(props.product.Size);
            setProductCost(props.product.Cost);
            setProductQuantity(props.product.Quantity);
        }
    }, [props.product]);

    // Function to add products to database
    const addProduct = async (event) => {
        event.preventDefault();
        var form = event.target;

        // Ensure product name is all capitalized
        const name = form[0].value;
        const fixedName = name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        const formProduct = {
            Name: fixedName,
            Image: form[1].value,
            Category: form[2].value,
            SubCategory: form[3].value,
            Brand: customBrand == '' ? form[4].value : form[5].value,
            Size: customBrand == '' ? form[5].value : form[6].value,
            Cost: customBrand == '' ? Number(form[6].value) : Number(form[7].value),
            Quantity: customBrand == '' ? Number(form[7].value) : Number(form[8].value),
            Timestamp: new Date()
        };

        console.log(formProduct)
        if (props.product) {
            try {
                await updateDoc(doc(db, "products", props.product.id), formProduct);
                document.getElementById("form-response").innerHTML = "Successfully updated " + form[0].value + "."
            } catch (e) {
                console.error("Error updating document: ", e);
            }
        }
        else {
            try {
                const docRef = await addDoc(collection(db, "products"), formProduct);
                document.getElementById("form-response").innerHTML = "Successfully added " + form[0].value + "."
                console.log("Document written with ID: ", docRef.id);
            } catch (e) {
                console.error("Error adding document: ", e);
            }

            // Reset form
            document.getElementById("Name").value = ""
            document.getElementById("Image").value = ""
            document.getElementById("Size").value = ""
            document.getElementById("Quantity").value = ""
            document.getElementById("Cost").value = ""

            // Reset preview
            setProductName("")
            setProductImage("")
            setProductSize("")
            setProductQuantity(0)
            setProductCost(0)
            // console.log(props)
        }
        props.onClose()
        props.getProducts()

    }

    return (
        <>
            <div className="overlay" onClick={props.onClose}></div>
            <div className='modal-container'>
                <div className='product-info'>
                    <form id="product-form" className="product-form" onSubmit={addProduct}>
                        <h1 id="form-type-text">{props.product ? 'Modify a Product' : 'Add a Product'}</h1>
                        <div className='product-form-section' >
                            <label className='product-form-name' >Name</label>
                            <input id='Name' className='product-form-value' value={productName} onChange={(event) => { setProductName(event.target.value) }}></input>
                        </div>
                        <div className='product-form-section' >
                            <label className='product-form-name'>Image</label>
                            <input id='Image' className='product-form-value' value={productImage} onChange={event => setProductImage(event.target.value)}></input>
                        </div>
                        <div className='product-form-section' >
                            <label className='product-form-name'>Category</label>
                            <select className="product-dropdown" onChange={(e) => handleCategoryChange(e.target.value)} value={productCategory}>
                                {categories.map((category) => (
                                    <option key={category} value={category}>
                                        {category}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='product-form-section' >
                            <label className='product-form-name'>Sub Category</label>
                            <select className="product-dropdown" onChange={(e) => handleSubcategoryChange(e.target.value)} value={productSubcategory}>
                                {subcategories.map((subcategory) => (
                                    <option key={subcategory.id} value={subcategory.name}>
                                        {subcategory.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='product-form-section' >
                            <label className='product-form-name'>Brand</label>
                            {/* <input id='Brand' className='product-form-value' value={productBrand} onChange={event => setProductBrand(event.target.value)}></input> */}
                            <select className="product-dropdown" onChange={(e) => handleBrandChange(e.target.value)} value={productBrand}>
                                {brands.map((brand) => (
                                    <option key={brand} value={brand}>
                                        {brand}
                                    </option>
                                ))}
                                <option>+ Add a New Brand</option>
                            </select>
                        </div>
                        {productBrand == '+ Add a New Brand' && (
                            <>
                                <div className='product-form-section'>
                                    <label className='product-form-name'>New Brand</label>
                                    <input id='Name' className='product-form-value' type="text" value={customBrand} onChange={handleCustomBrandChange} placeholder="" />
                                </div>
                            </>
                        )}
                        <div className='product-form-section' >
                            <label className='product-form-name'>Size</label>
                            <input id='Size' className='product-form-value' value={productSize} onChange={event => setProductSize(event.target.value)}></input>
                        </div>
                        <div className='product-form-section' >
                            <label className='product-form-name'>Cost</label>
                            <input id='Cost' className='product-form-value' value={productCost} onChange={event => setProductCost(event.target.value)}></input>
                        </div>
                        <div className='product-form-section' >
                            <label className='product-form-name'>Quantity</label>
                            <input id='Quantity' className='product-form-value' value={productQuantity} onChange={event => setProductQuantity(event.target.value)}></input>
                        </div>
                        <button>{props.product ? 'Update Product' : 'Add Product'}</button>
                        <div id='form-response' className='form-response' />
                    </form>
                </div>
                <div className='product-preview'>
                    <Product
                        Name={productName}
                        Brand={customBrand == '' ? productBrand : customBrand}
                        Image={productImage}
                        Size={productSize}
                        Cost={Number(productCost)}
                        Quantity={productQuantity}
                    ></Product>
                </div>
            </div>
        </>
    );
};