import { collection, getDocs, query, orderBy, limit, where } from "firebase/firestore";
import { db } from '../../adapters/firebase';
import { useState, useEffect } from 'react';
import Product from './../../components/Product/index';
import './styles.css';

const Home = ({setProducts}) => {

  const hairProducts = [
    {
      id: 1,
      Image: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimgix.bustle.com%2Fuploads%2Fimage%2F2019%2F12%2F10%2Ff1464e0a-4d4d-416e-bcd8-3390b86843de-best-products-hold-curl-texture-tease.jpg%3Fw%3D400%26h%3D400%26fit%3Dcrop%26crop%3Dfaces%26auto%3Dformat%252Ccompress%26q%3D50%26dpr%3D2&f=1&nofb=1&ipt=241ab06af35736bf5bbadd278de585da355f8cd920c0f262a6ff8857c1db07d8&ipo=images',
      Name: 'Shiny Hair Shampoo',
      Size: '300ml',
      Cost: 10.99,
      Quantity: 0,
      Brand: 'Garnier',
      Category: 'Shampoo',
    },
    {
      id: 2,
      Image: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimgix.bustle.com%2Fuploads%2Fimage%2F2019%2F12%2F10%2Ff1464e0a-4d4d-416e-bcd8-3390b86843de-best-products-hold-curl-texture-tease.jpg%3Fw%3D400%26h%3D400%26fit%3Dcrop%26crop%3Dfaces%26auto%3Dformat%252Ccompress%26q%3D50%26dpr%3D2&f=1&nofb=1&ipt=241ab06af35736bf5bbadd278de585da355f8cd920c0f262a6ff8857c1db07d8&ipo=images',
      Name: 'Silky Smooth Conditioner',
      Size: '250ml',
      Cost: 8.99,
      Quantity: 3,
      Brand: 'Garnier',
      Category: 'Conditioner',
    },
    {
      id: 3,
      Image: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimgix.bustle.com%2Fuploads%2Fimage%2F2019%2F12%2F10%2Ff1464e0a-4d4d-416e-bcd8-3390b86843de-best-products-hold-curl-texture-tease.jpg%3Fw%3D400%26h%3D400%26fit%3Dcrop%26crop%3Dfaces%26auto%3Dformat%252Ccompress%26q%3D50%26dpr%3D2&f=1&nofb=1&ipt=241ab06af35736bf5bbadd278de585da355f8cd920c0f262a6ff8857c1db07d8&ipo=images',
      Name: 'Curl Enhancing Styling Shampoo',
      Size: '200ml',
      Cost: 12.49,
      Quantity: 30,
      Brand: 'Dove',
      Category: 'Styling',
    },
    {
      id: 4,
      Image: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimgix.bustle.com%2Fuploads%2Fimage%2F2019%2F12%2F10%2Ff1464e0a-4d4d-416e-bcd8-3390b86843de-best-products-hold-curl-texture-tease.jpg%3Fw%3D400%26h%3D400%26fit%3Dcrop%26crop%3Dfaces%26auto%3Dformat%252Ccompress%26q%3D50%26dpr%3D2&f=1&nofb=1&ipt=241ab06af35736bf5bbadd278de585da355f8cd920c0f262a6ff8857c1db07d8&ipo=images',
      Name: 'Argan Oil Hair Shampoo',
      Size: '100ml',
      Cost: 15.99,
      Quantity: 25,
      Brand: 'Dove',
      Category: 'Serum',
    },
    {
      id: 5,
      Image: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimgix.bustle.com%2Fuploads%2Fimage%2F2019%2F12%2F10%2Ff1464e0a-4d4d-416e-bcd8-3390b86843de-best-products-hold-curl-texture-tease.jpg%3Fw%3D400%26h%3D400%26fit%3Dcrop%26crop%3Dfaces%26auto%3Dformat%252Ccompress%26q%3D50%26dpr%3D2&f=1&nofb=1&ipt=241ab06af35736bf5bbadd278de585da355f8cd920c0f262a6ff8857c1db07d8&ipo=images',
      Name: 'Volumizing Conditioner',
      Size: '150ml',
      Cost: 9.99,
      Quantity: 35,
      Brand: 'Dove',
      Category: 'Mousse',
    },
  ];

  // Reference to products data
  const productCollectionRef = collection(db, "products");
  const [recentProducts, setRecentProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);

  // Function to get products from database
  const getProducts = async () => {

    // DEBUG
    // const availableHairProducts = hairProducts.filter(product => product.Quantity !== 0);
    // setRecentProducts(availableHairProducts.slice(0,3));
    // setAllProducts(availableHairProducts);

    // Get recent products sorted by added date
    var queryResult = query(productCollectionRef, where("Quantity", ">", 0), orderBy("Quantity"), orderBy("Timestamp", "desc"), limit(4));
    var data = await getDocs(queryResult);
    setRecentProducts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

    // Get all products sorted by name
    queryResult = query(productCollectionRef, orderBy("Brand"));
    data = await getDocs(queryResult);
    var availableProducts = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    setAllProducts(availableProducts.filter(product => product.Quantity !== 0));
  }

  // Get products from database
  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    setProducts(allProducts);
  }, [allProducts])

  return (
    <div className="home">
      {/* <div className='motto'>
        <b className='motto-text'>Deals Ripe for the Picking</b>
        <img className='motto-image' src='https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fthriftymommaramblings.com%2Fwp-content%2Fuploads%2F2017%2F10%2Fcouponing.jpg&f=1&nofb=1&ipt=5df29ae47c15c35a5176359f996c6d8dd8771b189d0ad6d90dc39df2da503f68&ipo=images'></img>
      </div> */}
      <h2>Recently Added Products</h2>
      <div className='products'>
        {recentProducts.map((product) => {
          return (
            <Product
              id={product.id}
              Brand={product.Brand}
              Name={product.Name}
              Image={product.Image}
              Size={product.Size}
              Cost={product.Cost}
              Quantity={product.Quantity}
            ></Product>
          )
        })}
      </div>
      <h2> All Available Products</h2>
      <div className='products'>
        {allProducts.map((product) => {
          return (
            <Product
              Name={product.Name}
              Brand={product.Brand}
              Image={product.Image}
              Size={product.Size}
              Cost={product.Cost}
              Quantity={product.Quantity}
            ></Product>
          )
        })}
      </div>
    </div>
  );
}

export default Home;