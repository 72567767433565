import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './styles.css';

const Header = ({ products }) => {

    function searchProduct() {
        document.location.href = "/search?" + document.getElementById('header-search-bar').value;
    }

    const [showOverlay, setShowOverlay] = useState(false);
    const toggleOverlay = () => {
        console.log('clicked');
        setShowOverlay(!showOverlay);
    };

    return (
        <header className='header'>
            <div className='header-left'>
                <img className='header-menu' src='https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn2.iconfinder.com%2Fdata%2Ficons%2Fmost-useful-icons-4%2F50%2FHAMBURGER_MENU-512.png&f=1&nofb=1&ipt=e95db87d64930fee189fa74d1b7d7b431883cac4ac633cdbb2c80b4b582f03b8&ipo=images'
                    onClick={() => toggleOverlay()}></img>
                <Link to="/" className='header-logo'>
                    <img className='header-logo-image' src='https://cdn-icons-png.flaticon.com/512/2083/2083677.png' alt='Logo'></img>
                    <h1 className='header-title'>Banana Couponing</h1>
                </Link>
                <div className='header-categories'>
                    <Link to="/categories/household" state={{ data: products }}>Household</Link>
                    <Link to="/categories/personal care" state={{ data: products }}>Personal Care</Link>
                    <Link to="/categories/beauty" state={{ data: products }}>Beauty</Link>
                    <Link to="/categories/health" state={{ data: products }}>Health</Link>
                    <Link to="/categories/kids" state={{ data: products }}>Kids</Link>
                </div>
            </div>
            {showOverlay && (
                <>
                    <div className={`overlay ${showOverlay ? 'show' : ''}`} onClick={() => toggleOverlay()}></div>
                    <div className='overlay-content'>
                        <div className='overlay-header'>
                            <img className='header-logo-image' src='https://cdn-icons-png.flaticon.com/512/2083/2083677.png' alt='Logo'></img>
                            <img className='header-menu-close' src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fislandfreshbermuda.com%2Fwp-content%2Fuploads%2F2021%2F10%2Fblack-x-png-7-png-image-black-x-png-1600_1600.png&f=1&nofb=1&ipt=6fc9c4718c84544b9824f13b181d5d50da7792bce6fafbf2fdb909eefcd8cd74&ipo=images"
                                onClick={() => toggleOverlay()}></img>
                        </div>
                        <div className='overlay-links'>
                            <h2>Categories</h2>
                            <div className='overlay-divider'></div>
                            <Link to="/categories/household" state={{ data: products }} onClick={() => toggleOverlay()}>Household</Link>
                            <Link to="/categories/personal care" state={{ data: products }} onClick={() => toggleOverlay()}>Personal Care</Link>
                            <Link to="/categories/beauty" state={{ data: products }} onClick={() => toggleOverlay()}>Beauty</Link>
                            <Link to="/categories/health" state={{ data: products }} onClick={() => toggleOverlay()}>Health</Link>
                            <Link to="/categories/kids" state={{ data: products }} onClick={() => toggleOverlay()}>Kids</Link>
                        </div>
                    </div>
                </>
            )}

            <div className='header-middle'>
                <div className='header-search'>
                    <input id="header-search-bar" className='header-search-bar' placeholder='Search products'></input>
                    <img className='header-search-image' onClick={searchProduct} src='https://cdn-icons-png.flaticon.com/512/5948/5948534.png' alt='Search'></img>
                </div>
            </div>

            <div className='header-right'>
                <div className='header-links'>
                    <Link to="/cart" className='header-cart'>
                        <img className='header-cart-image' src='https://cdn-icons-png.flaticon.com/512/3144/3144456.png' alt='Cart'></img>
                        <div>My Cart</div>
                    </Link>
                    <Link to="/login" className='header-cart'>
                        <img className='header-cart-image' src='https://cdn-icons-png.flaticon.com/512/1144/1144760.png' alt='Admin'></img>
                        <div>Admin</div>
                    </Link>
                </div>
            </div>
        </header>

    );
};

export default Header;