import React from 'react';
import { useState, useEffect } from 'react';
import { collection, getDocs, where } from "firebase/firestore";
import { db } from '../../adapters/firebase';
import Product from './../../components/Product/index';
import './styles.css';

export default function Search() {
    var searchValue = document.location.href.split('/')[3].split('?')[1];

    // Reference to products data
    const productCollectionRef = collection(db, "products");
    const [products, setProducts] = useState([]);

    // Function to get products from database based on search parameter
    const getProducts = async () => {
        const data = await getDocs(productCollectionRef, where("Quantity", "!=", 0));
        var allProducts = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        allProducts = allProducts.filter(product => product.Quantity !== 0);
        var matchedProducts = [];

        // Searching for value in Name and Category
        matchedProducts = allProducts.filter(
            product => product.Name.toLowerCase().includes(searchValue) || 
                        product.Category.toLowerCase().includes(searchValue) ||
                        product.Brand.toLowerCase().includes(searchValue)
          );
        setProducts(matchedProducts);

        // If no matched products
        if (matchedProducts.length == 0)
        {
            document.getElementById("search-message").innerHTML = "No results found :(";
        }
    }

    // Get products from database
    useEffect(() => {
        getProducts();
    }, []);

    return (
        <div className='search'>
            <h1>Searching for: {searchValue}</h1>
            <div className='products'>
                {products.map((product) => {
                    return (
                        <Product
                            Name={product.Name}
                            Image={product.Image}
                            Size={product.Size}
                            Cost={product.Cost}
                            Quantity={product.Quantity}
                        ></Product>
                    )
                })}
            </div>
            <h2 id='search-message'></h2>
        </div>
    );
};