import React from 'react';
import './styles.css';

export default function Checkout() {

    return (
        <div>
            Checkout Page
        </div>
    );
};