import React from 'react';
import { useState, useEffect } from 'react';
import { query, orderBy } from "firebase/firestore";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { db } from '../../adapters/firebase';
import ProductForm from '../../components/ProductForm';
import Product from '../../components/Product';
import subCategories from '../../assets/subData.json';
import './styles.css';

export default function Admin() {

    // Reference to products data
    const productCollectionRef = collection(db, "products");
    const [products, setProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [inStockProducts, setInStockProducts] = useState([]);
    const [outOfStockProducts, setOutOfStockProducts] = useState([]);
    const [baseProducts, setBaseProducts] = useState([]);
    const [view, setView] = useState('all');

    // Function to get products from database
    const getProducts = async () => {

        // Get products from Firebase database
        const products = query(productCollectionRef, orderBy("Brand", "asc"));
        var data = await getDocs(products);
        const productsData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))

        // Set current products, all products, and base products
        await setProducts(productsData);
        await setAllProducts(productsData);
        await setBaseProducts(productsData);

        // Set product statistics
        document.getElementById("products-total-amount").innerHTML = productsData.length;
        await setInStockProducts(productsData.filter(product => product.Quantity !== 0))
        document.getElementById("products-in-stock-amount").innerHTML = productsData.filter(product => product.Quantity !== 0).length;
        await setOutOfStockProducts(productsData.filter(product => product.Quantity === 0))
        document.getElementById("products-no-stock-amount").innerHTML = productsData.filter(product => product.Quantity === 0).length;

        // If finish here, every time a product is added or modified, the page will refresh back to
        //   the All Product view with the search ignored. Need to do the following:
        //   1. Keep the page on the correct view. (Done)
        //   2. Keep the search intact.
        // console.log('Testing product:')
        // console.log(productsData.filter(product => product.Name.includes("AA")).at(0).Name)
        // console.log('Current view: ',view)
        // if(view !== 'all'){
        //     await switchView(view);
        // }
        // if(document.getElementById('search-value').value){
        //     await searchProduct();
        // }
    }

    // Switch base products based on 
    const switchView = (view) => {
        if (view === 'in') {
            console.log("set view to in stock")
            setView('in')
            setProducts(inStockProducts);
            setBaseProducts(inStockProducts);
        }
        else if (view === 'out') {
            console.log("set view to out of stock")
            setView('out')
            setProducts(outOfStockProducts);
            setBaseProducts(outOfStockProducts);
        }
        else {
            console.log('set view to all')
            setView('all')
            setProducts(allProducts);
            setBaseProducts(allProducts);
        }
        // Weird behavior, fix this hun

        // if(document.getElementById('search-value').value){
        //     searchProduct();
        // }
        // searchProduct();
    }

    const [isModifyProductModalOpen, setModifyProductModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const openModifyProductModal = (product) => {
        setSelectedProduct(product);
        setModifyProductModalOpen(true);
    };

    const closeModifyProductModal = () => {
        setSelectedProduct(null);
        setModifyProductModalOpen(false);
    };

    const searchProduct = () => {
        const searchValue = document.getElementById('search-value').value.toLowerCase();
        // console.log(products);
        // console.log(baseProducts);

        if (searchValue === '') {
            //   setProducts(baseProducts);
            //   console.log("Reset products");
        } else {
            //   console.log("Searching for " + searchValue);
            // Searching for value in Name and Category
            const matchedProducts = baseProducts.filter(
                product => product.Name.toLowerCase().includes(searchValue) ||
                    product.Category.toLowerCase().includes(searchValue) ||
                    product.Brand.toLowerCase().includes(searchValue)
            );

            // DEBUG
            setProducts(matchedProducts);
        }
    };

    const addSubcategories = () => {
        // const subCategoriesBrand = categoryData[];
        // Object.keys(subCategories).forEach((category) => {
        //     console.log(`Category: ${category}`);

        //     // Iterate through each item in the category
        //     subCategories[category].forEach((brand) => {
        //         // console.log(brand.name)
        //         const brandProducts = allProducts.filter(product => product.Brand == brand.name)
        //         // console.log(brandProducts)

        //         brandProducts.forEach((product) => {
        //             try {
        //                 updateDoc(doc(db, "products", product.id), { Subcategory: category });
        //                 console.log( "Successfully updated " + product.id + ".")
        //             } catch (e) {
        //                 console.error("Error updating document: ", e);
        //             }
        //         })
        //     });
        // });
    }


    // Get products from Firebase database
    useEffect(() => {
        getProducts();
    }, []);

    return (
        <div className='admin'>
            <h1>Welcome Back Mom :)</h1>
            <h2>Product Statistics</h2>
            <div className='product-stats'>
                <div className='product-stats-box' onClick={() => { switchView() }}>
                    <h2>Total</h2>
                    <h1 id='products-total-amount' className='products-total-amount'>0</h1>
                </div>
                <div className='product-stats-box' onClick={() => { switchView('in') }}>
                    <h2>In Stock</h2>
                    <h1 id='products-in-stock-amount' className='products-in-stock-amount'>0</h1>
                </div>
                <div className='product-stats-box' onClick={() => { switchView('out') }}>
                    <h2>Out of Stock</h2>
                    <h1 id='products-no-stock-amount' className='products-no-stock-amount'>0</h1>
                </div>
            </div>
            <div className='admin-buttons'>
                <h2>Admin Functions</h2>
                <button onClick={() => openModifyProductModal()}>Add Product</button>
                {isModifyProductModalOpen && (<ProductForm product={selectedProduct} onClose={closeModifyProductModal} getProducts={getProducts} allProducts={allProducts} />)}
                <button>Log a Haul</button>
                <button>View Orders</button>
                <button onClick={() => addSubcategories()}>Add Subcategories</button>
            </div>
            <h1>{view === "in" ? 'In Stock Products:' : view === "out" ? 'Out of Stock Products:' : 'All Products:'}</h1>
            <div className='admin-search'>
                <h3>Keyword Search</h3>
                <input id="search-value" onChange={searchProduct} className='search-bar' type='text'></input>
                <img className='search-image' onClick={searchProduct} src='https://cdn-icons-png.flaticon.com/512/5948/5948534.png' alt="uh oh"></img>
            </div>
            <div className='products'>
                {products.map((product) => {
                    return (
                        <Product
                            key={product.id}
                            Name={product.Name}
                            Image={product.Image}
                            Brand={product.Brand}
                            Category={product.Category}
                            Subcategory={product.Subcategory}
                            Size={product.Size}
                            Cost={product.Cost}
                            Quantity={product.Quantity}
                            id={product.id}
                            Admin={true}
                            GetProducts={getProducts}
                            AllProducts={allProducts}
                            Modify={openModifyProductModal}
                        ></Product>
                    )
                })}
            </div>
        </div>
    );
};