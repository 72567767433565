import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { auth } from '../../adapters/firebase';

import React from 'react';
import './styles.css';

export default function Login() {

    const navigate = useNavigate();

    function login(event) {
        event.preventDefault();
        var password = event.target[0].value;

        if (password == ""){
            document.getElementById("login-error").innerHTML = "Please provide a password.";
            return;
        }

        signInWithEmailAndPassword(auth, "amanda@admin.com", password)
            .then((userCredential) => {
                
                navigate('/admin')
            })
            .catch((error) => {
                if (error.code == "auth/wrong-password") {
                    document.getElementById("login-error").innerHTML = "Incorrect password.";
                }
                else {
                    console.log(error.message);
                    document.getElementById("login-error").innerHTML = "Unkown error. Please contact administrator.";
                }
            });
    };

    return (
        <div className="login">
            <h1>Login to Admin</h1>
            <form className="login-form" id="login-form" onSubmit={login}>
                <label>Password</label>
                <input type="password"/>
                <button>Login</button>
            </form>
            <div id="login-error" className="login-error"></div>
        </div>
    );
};