import { updateDoc, deleteDoc, doc } from "firebase/firestore";
import { db } from '../../adapters/firebase';
import React from 'react';
import './styles.css';

export default function Product(props) {
    let button;
    if (props.Quantity !== 0 && props.Admin !== true) {
        button = <button id='product-add' className='product-add'>+ Add to Cart</button>;
    }

    // Function to update product quantity
    const updateProduct = async (id, quantity) => {
        const productDoc = doc(db, "products", id);
        const newValues = { Quantity: quantity }
        // Ideally, we'd use below code. However, changes display order on Admin page
        // const newValues = { Quantity: quantity, Timestamp: new Date() }
        await updateDoc(productDoc, newValues);

        // Display updated product
        props.GetProducts();
    };

    // Function to delete product
    const deleteProduct = async (id) => {
            await deleteDoc(doc(db, "products", id));

            // Display updated product
            props.GetProducts()
        // }
        
        
    };

    if(props.Admin === true) {
        var buttons = [
        <button onClick={(e) => {props.Modify(props)}}>Modify Product</button>,
        <button onClick={(e) => { updateProduct(e.currentTarget.parentElement.parentElement.id, Number(e.currentTarget.parentElement.parentElement.children[3].innerHTML.split(" ")[0]) + 1) }}>+</button>,
        <button onClick={(e) => { updateProduct(e.currentTarget.parentElement.parentElement.id, Number(e.currentTarget.parentElement.parentElement.children[3].innerHTML.split(" ")[0]) - 1) }}>-</button>,
        <button onClick={(e) => { deleteProduct(e.currentTarget.parentElement.parentElement.id)}} id='delete-button'>Delete Product</button>];
    }
    
    return (
        <div className='product' id={props.id} key={props.id}>
            <img className='product-image' src={props.Image} alt=""/>
            <h2 className='product-name'><span className="product-brand">{props.Brand}</span> {props.Name}, {props.Size}</h2>
            <h3 className='product-cost'>${props.Cost.toFixed(2)}</h3>
            <h3 className='product-quantity'>{props.Quantity} Available</h3>
            {button}
            <div className='admin-buttons'>
            {buttons}
            </div>
        </div>
    );
};